import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/Layout"
import HeroHeader from "../../components/HeroHeader"
import HeroImage from "../../images/foodbeverage.jpg"
import SEO from "../../components/SEO"
import ApplicationQuote from "../../components/ApplicationQuotes"
import FoodBeverageImages from "../../components/applicationImageComponents/foodBeverageImages"

const PageBody = styled.main`
  max-width: 1000px;
  margin: 0 auto;

  h1 {
    font-size: 2.5em;
  }
`

const Modal = styled.div`
  position: fixed;
  width: 900px;
  height: 600px;
  bottom: 50%;
  margin-bottom: -300px;
  left: 50%;
  margin-left: -450px;
  background-color: green;
  animation: fadeIn ease 0.5s;
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 910px) {
    width: 750px;
    height: 500px;
    margin-bottom: -250px;
    margin-left: -375px;
  }

  @media (max-width: 775px) {
    width: 600px;
    height: 400px;
    margin-bottom: -200px;
    margin-left: -300px;
  }

  @media (max-width: 625px) {
    width: 450px;
    height: 300px;
    margin-bottom: -150px;
    margin-left: -225px;
  }

  @media (max-width: 475px) {
    width: 350px;
    height: 233px;
    margin-bottom: -116.5px;
    margin-left: -175px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  i {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;
    color: red;
    font-size: 2em;
    cursor: pointer;
  }

  button {
    background-color: transparent;
    padding: 0.5rem;
    border: 1px solid black;
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  #machine-btn {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

  #application-btn {
    position: absolute;
    bottom: 30px;
    left: 145px;
  }
`

const StyledImageGalleryHeader = styled.h2`
  max-width: 1400px;
  margin: 1rem auto;
  border-bottom: 2px solid red;
`

const StyledImageGallery = styled.div`
  max-width: 1400px;
  display: flex;
  flex-flow: row wrap;
  margin: 1rem auto;
  justify-content: space-between;
`

const Thumbnail = styled.div`
  position: relative;
  max-width: 330px;
  max-height: 330px;
  overflow: hidden;
  cursor: pointer;
  margin: 10px 0;

  i {
    position: absolute;
    top: 10px;
    right: 20px;
    color: white;
    font-size: 1.4em;
    /* z-index: 3; */
    pointer-events: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    &:hover {
      color: red;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    display: none;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    bottom: 0;
    color: white;
    animation: slideIn ease 0.5s;
    pointer-events: none;

    @keyframes slideIn {
      0% {
        margin-bottom: -150px;
      }
      100% {
        margin-bottom: 0;
      }
    }

    h4 {
      margin-left: 15px;
    }

    a {
      text-decoration: none;
      color: white;
      pointer-events: auto;
      &:hover {
        color: red;
      }
    }
  }

  &:hover {
    .overlay {
      display: block;
    }
  }
`

const BackButton = styled.button`
  margin: 2rem;
  font-size: 1em;
  border: none;
  padding: 1rem;
  background-color: red;
  color: white;
  cursor: pointer;
`

const FoodPage = () => {
  const [modal, setModal] = useState({
    display: "none",
    name: "",
  })
  const [machineImage, setMachineImage] = useState({})
  const [machineLink, setMachineLink] = useState({})

  const [model, setModel] = useState({
    name: "",
  })

  function handleImageClick(e) {
    setModal({
      display: "block",
      name: e.target.dataset.model,
    })
    setMachineImage(e.target.src)
    setMachineLink(e.target.dataset.link)
    setModel({ name: e.target.dataset.model })
  }

  return (
    <>
      <SEO title="Food and Beverage" description="" />

      <Layout>
        <HeroHeader
          heroImage={HeroImage}
          // title="Warehouse Application"
          // subHeading="Keep Your Unique Warehouse Clean with Our Specialized Products."
          buttonTitle="See More"
          showButton="none"
        />
        <PageBody>
          <h1>Food and Beverage</h1>
          <p>
            Facilities that process, package or redistribute food and beverages
            have unique needs and high expectations. A floor scrubber that
            cleans effectively, while containing the dust and fluids well is
            highly sought. Factory Cat has always enjoyed a strong customer base
            with the produce packing house, in addition to the battery scrubbers
            in use at the processing facilities. The fully cleanable recovery
            tank permits complete sanitization of the tank, which otherwise
            could harbor all sorts of undesirables, to be push out like an
            aerosol by the vacuum motor.
          </p>
          <h2>We Provide the Best Cleaning for your Specific Needs:</h2>
          <ul>
            <li>
              Some of our rider scrubber platforms can be built with 304
              stainless steel tanks, which permit hot water and even better
              sanitation / disinfection.
            </li>
            <li>
              Whether the facility is bottling water, sodas, tea, beer, or any
              other fluids, our battery scrubbers are designed for optimum
              satisfaction.
            </li>
            <li>
              The cylindrical scrubbing head is able to clean up a 12-pack of
              broken glass bottles, the caps and all fluids in a single pass.
              Thereby reducing the potential for sharp objects getting lodged in
              the rubber blades or clogged in the recovery hoses.
            </li>
          </ul>
          <ApplicationQuote quote="Sanitation is important when you are working around things people eat or drink. FactoryCat scrubbers have wide open cleanout tanks and can be ordered with antimicrobial plastics and stainless steel decks making sanitizing easy. -JC" />
          <ApplicationQuote quote="Stainless Steel Scrub decks meet expectations of equipment in a food facility.  Zero3 prevents bacteria and ordors in recovery tanks.  P7, double-sipe drive tire provides excellent traction in low-traction production areas. -KB" />
          <ApplicationQuote quote="Automatic floor scrubbers are a favorite among food and beverage distributors due to their rugged build quality and deep cleaning power on all floor types. -JM" />
        </PageBody>
        <FoodBeverageImages />
        <Link to="/applications">
          <BackButton>All Applications</BackButton>
        </Link>
      </Layout>
    </>
  )
}

export default FoodPage
